import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Checkbox, Heading, Layout, Select, Separator, Tag, TextField } from "@fleet.co/tarmac";
import { calculateLeaserRate, getLeaserList } from "../../../tools/OrderTools";
import LoadingSpinner from "../../common/LoadingSpinner";
import UserContext from "../../../tools/UserContext";
import { leasersList } from "../../../data/leasersList";
import ConfirmLeaserChoice from "../../blocks/ConfirmLeaserChoice";
import LeaserBlock from "../../blocks/LeaserBlock";
import CompanyEnvelopeInfo from "./CompanyEnvelopeInfo";

const OrderLeaser = (props) => {
  const { user: adminUser } = useContext(UserContext);
  const { order, reload } = props;
  const leasers = getLeaserList(order);

  const [showLeaserChoiceReasonModal, setLeaserChoiceReasonModalOpen] = useState(false);

  const knowContractNumberLeaser = leasersList.map((l) => l.has_same_request_contract_number && l.name).filter(Boolean);

  const isManualLeaser = (lr) => {
    const l = leasersList.find((l) => l.name === lr.leaser);

    return !l.has_auto_request;
  };
  const initial_leaser_status = order.leaser_requests
    .filter(isManualLeaser)
    .map((lr) => ({ name: lr.leaser, status: lr.status }));

  const initial_order_leaser_info = {
    leaser: order.leaser ? order.leaser : "",
    leaser_rate: order.leaser_rate ? order.leaser_rate : "",
    cosme: order.cosme,
    net_revenue: order.net_revenue ? order.net_revenue : "",
    contract_duration: order.contract_duration ? order.contract_duration : "",
    request_number: order.request_number ? order.request_number : "",
    contract_number: order.contract_number ? order.contract_number : "",
    wants_insurance: order.wants_insurance || false,
    leaser_status: initial_leaser_status,
    is_AFS: order.is_AFS,
  };

  const isPrinterProductInOrder = order?.devices?.some((device) => device.product.product_group.category === "PRINTER");

  const [isEditable, setIsEditable] = useState(false);
  const [orderLeaserInfo, setOrderLeaserInfo] = useState(initial_order_leaser_info);
  const [envelopeInfos, setEnvelopeInfos] = useState(null);

  const [scoringErrorMessage, setScoringErrorMessage] = useState(null);
  const [infoErrorMessage, setInfoErrorMessage] = useState(null);

  // TODO : Factor
  const setLeaserStatus = (leaserName, status) => {
    let updatedLeaserStatus = [];

    if (orderLeaserInfo.leaser_status.find((ls) => ls.name === leaserName)) {
      updatedLeaserStatus = orderLeaserInfo.leaser_status.map((ls) =>
        ls.name === leaserName ? { name: leaserName, status } : ls,
      );
    } else {
      updatedLeaserStatus = [...orderLeaserInfo.leaser_status, { name: leaserName, status }];
    }

    setOrderLeaserInfo((prevInfo) => ({ ...prevInfo, leaser_status: updatedLeaserStatus }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setOrderLeaserInfo((prevInfo) => ({ ...prevInfo, [name]: value }));

    // Leaser-rate changed -> Update Net Revenue
    if (name === "leaser_rate") {
      const lr = parseFloat(value);

      const recommendedLeaserRate = calculateLeaserRate(orderLeaserInfo.leaser, orderLeaserInfo.is_AFS);
      const calculatedNetRevenue = ((order.rent * 100) / lr).toFixed(2);

      setOrderLeaserInfo((prevInfo) => ({ ...prevInfo, net_revenue: calculatedNetRevenue }));

      if (recommendedLeaserRate && lr !== parseFloat(recommendedLeaserRate)) {
        setInfoErrorMessage("Attention, le taux est différent du leaser rate recommandé.");
      }

      if (lr === parseFloat(recommendedLeaserRate)) {
        setInfoErrorMessage(null);
      }
    }
  };

  const selectLeaser = (value) => {
    setInfoErrorMessage(null);

    if (value === "") {
      setOrderLeaserInfo((prevInfo) => ({ ...prevInfo, leaser: "" }));
    }
    // Do that only in the Front End so the sales can modify data if wanted, they know what they are validating
    const matchingRequest = order?.leaser_requests.find(
      (request) => request.leaser === value && request.status === "ACCEPTED",
    );
    const shouldUpdateContractNumber = matchingRequest && knowContractNumberLeaser.includes(value);

    setOrderLeaserInfo((prevInfo) => ({
      ...prevInfo,
      request_number: matchingRequest?.final_request_number ? matchingRequest.final_request_number : "",
      contract_number: shouldUpdateContractNumber ? matchingRequest.final_request_number : "",
      cosme: matchingRequest?.cosme,
      leaser: value,
      leaser_rate: calculateLeaserRate(value, orderLeaserInfo.is_AFS),
      contract_duration: value === "Upfront" ? 1 : 36,
    }));
  };

  const handleSelect = (value) => {
    setOrderLeaserInfo((prevInfo) => ({ ...prevInfo, leaser_reason: value }));
  };

  const handleChangeCheckbox = (name) => {
    setOrderLeaserInfo((prevInfo) => ({ ...prevInfo, [name]: !prevInfo[name] }));
  };

  const saveChanges = async () => {
    setOrderLeaserInfo((prevInfo) => ({ ...prevInfo, best_leaser: leasers[0]?.name }));
    const isLeaserEmpty = orderLeaserInfo.leaser !== "";
    const isLeaserDifferentFromBestLeaser = orderLeaserInfo.leaser !== leasers[0]?.name;
    const isLeaserHasChanged = initial_order_leaser_info.leaser !== orderLeaserInfo.leaser;

    if (isLeaserEmpty && isLeaserDifferentFromBestLeaser && isLeaserHasChanged) {
      // Leaser is not #1
      // Open the modal
      setLeaserChoiceReasonModalOpen(true);
    } else if (orderLeaserInfo !== initial_order_leaser_info) {
      updateOrder();
    } else {
      props.closeAction();
    }
  };

  const updateOrder = async () => {
    await adminUser.api.modifyOrder(order.id, orderLeaserInfo);
    setScoringErrorMessage(null);
    props.reload();
    setIsEditable(false);
  };

  const getEnvelopeInfos = async (id) => {
    const fetchedEnvelope = await adminUser.api.getEnvelope(id);

    setEnvelopeInfos(fetchedEnvelope.data);
  };

  useEffect(() => {
    if (order.envelope_id) {
      getEnvelopeInfos(order.envelope_id);
    }
  }, [order]);

  const leaserOptions = [
    { label: "Select a leaser", value: "" },
    ...leasersList.map((l) => ({ value: l.name, label: l.label })),
  ];

  if (!order) {
    return <LoadingSpinner isFullScreen />;
  }

  return (
    <Layout direction="column" spacing={4} style={{ maxWidth: "1024px", margin: "32px auto" }}>
      {envelopeInfos !== null && <CompanyEnvelopeInfo envelopeInfos={envelopeInfos} order={order} />}

      <Heading variant="h2">Leaser</Heading>

      {infoErrorMessage && (
        <Alert severity="error" onClose={() => setInfoErrorMessage(null)}>
          {infoErrorMessage}
        </Alert>
      )}

      <Layout direction="row" spacing={1} justifyContent="space-between">
        <Select
          label="Name"
          value={orderLeaserInfo.leaser}
          options={leaserOptions}
          disabled={!isEditable}
          onChange={selectLeaser}
        />

        <TextField
          label="Rate"
          type="number"
          disabled={!isEditable}
          name="leaser_rate"
          value={orderLeaserInfo.leaser_rate || ""}
          onChange={handleChange}
        />

        <TextField label="Suggested Rate" disabled className="is-static" value={orderLeaserInfo.leaser_rate || ""} />
        {orderLeaserInfo.leaser === "Franfinance" && (
          <Checkbox
            label="COSME"
            disabled={!isEditable}
            name="cosme"
            checked={orderLeaserInfo.cosme}
            onChange={() => handleChangeCheckbox("cosme")}
          />
        )}
      </Layout>
      <Layout direction="row" spacing={1} justifyContent="space-between">
        <TextField
          label="Duration"
          disabled={!isEditable}
          name="contract_duration"
          type="number"
          value={orderLeaserInfo.contract_duration}
          onChange={handleChange}
        />

        <TextField
          label="Request ID"
          disabled={!isEditable}
          name="request_number"
          type="text"
          value={orderLeaserInfo.request_number}
          onChange={handleChange}
        />

        <TextField
          label="Contract ID"
          type="text"
          disabled={!isEditable}
          name="contract_number"
          value={orderLeaserInfo.contract_number}
          onChange={handleChange}
        />
      </Layout>

      {order.is_AFS && (
        <Tag label="Eligible to BNP x Apple Financial Service" backgroundColor="green" variant="filled" />
      )}

      <Separator orientation="horizontal" />

      {isPrinterProductInOrder && (
        <Alert severity="warning">
          This order contains one or multiple printer products. Therefore, the leaser scoring is not available for this
          order.
        </Alert>
      )}

      {order.leaserScoring && (
        <section className="leaser-scoring-info">
          <Heading size="M">Leaser scoring status</Heading>
          <p>Our algorithm suggests the leasers most likely to accept the funding request</p>

          {scoringErrorMessage && (
            <Alert severity="error" onClose={() => setScoringErrorMessage(null)}>
              {scoringErrorMessage}
            </Alert>
          )}

          <Layout direction="column" spacing={2}>
            {leasers.map((leaser, index) => (
              <LeaserBlock
                key={leaser.name}
                order={order}
                index={index}
                leaser={leaser}
                reload={reload}
                isAuto={leaser.has_auto_request}
                setError={setScoringErrorMessage}
                leaserStatus={orderLeaserInfo.leaser_status.find((ls) => ls.name === leaser.name)}
                setStatus={(status) => setLeaserStatus(leaser.name, status)}
              />
            ))}
          </Layout>
        </section>
      )}

      <div style={{ position: "fixed", bottom: "100px", right: "1em" }}>
        {isEditable ? (
          <Layout direction="row" spacing={1}>
            <Button variant="contained" color="primary" label="Save Changes" onClick={saveChanges} />

            <Button variant="contained" color="secondary" label="Cancel" onClick={() => setIsEditable(false)} />
          </Layout>
        ) : (
          <Button variant="contained" color="secondary" label="Edit" onClick={() => setIsEditable(true)} />
        )}
      </div>

      <ConfirmLeaserChoice
        openModal={setLeaserChoiceReasonModalOpen}
        orderLeaserInfo={orderLeaserInfo}
        updateOrder={updateOrder}
        changeReason={handleSelect}
        open={showLeaserChoiceReasonModal}
      />
    </Layout>
  );
};

export default OrderLeaser;
