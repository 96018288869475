import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Drawer, Icon, Layout, Text } from "@fleet.co/tarmac";
import { useToastContext } from "src/contexts/ToastContext";
import { faCertificate, faCheck } from "@fortawesome/pro-regular-svg-icons";
import ConfigCheckbox from "./ConfigCheckbox";
import UserContext from "../../../../tools/UserContext";
import { productSpecs } from "../../../../data/productSpecs";
import { formatMoney } from "../../../../helpers/formatHelpers";

import styles from "./AddDeviceModal.module.scss";

const determineDeviceOS = (brand, category) => {
  const osMap = {
    apple: {
      COMPUTER: "macos",
      TABLET: "ipados",
      SMARTPHONE: "ios",
    },
    microsoft: {
      TABLET: "windows",
    },
    not_apple: {
      TABLET: "android",
      SMARTPHONE: "android",
      COMPUTER: "windows",
    },
  };

  const brandToMap = ["apple", "microsoft"].includes(brand) ? brand : "not_apple";

  return osMap[brandToMap]?.[category] || null;
};

const AddDeviceModal = (props) => {
  const { user: adminUser } = useContext(UserContext);
  const { addToast } = useToastContext();
  const navigate = useNavigate();

  const [device, setDevice] = useState({});

  useEffect(() => {
    if (!props.product) {
      return;
    }
    const device = {
      order_id: props.order.id,
      name: props.product.name,
      product_id: props.product.id,
      category: props.product_group.category,
      type: ["COMPUTER", "TABLET", "SMARTPHONE"].includes(props.product_group.category) ? "DEVICE" : "ACCESSORY",
      processor: props.product.processor,
      os_platform: determineDeviceOS(props.product_group.brand, props.product_group.category),
      storage: props.product.storage,
      ram: props.product.ram,
      quality: props.product.quality,
      screen_size: props.product.screen_size,
      connectivity: props.product.connectivity,
      graphic: props.product.graphic,
      noise_cancellation: props.product.noise_cancellation,
      has_microphone: props.product.has_microphone,
      connection_type: props.product.connection_type,
      compliance: props.product.compliance,
      refresh_rate: props.product.refresh_rate,
      response_time: props.product.response_time,
      screen_stand: props.product.screen_stand,
      definition: props.product.definition,
      equipment_sub_type: props.product.equipment_sub_type,
      battery_life: props.product.battery_life,
      optimized_to: props.product.optimized_to,
      power: props.product.power,
      model_year: props.product.model_year,
      rent: props.product.price,
      keyboard_layout: ["COMPUTER", "KEYBOARD"].includes(props.product_group.category)
        ? "FR - Français - AZERTY"
        : null,
    };

    setDevice(device);
  }, [props.product, props.product_group, props.order]);

  const handleChange = (name, value) => {
    if (
      name === "compliance" ||
      name === "connection_type" ||
      name === "available_countries" ||
      name === "screen_stand"
    ) {
      const optionsSelected = device[name] ? [...device[name]] : [];
      const optionIndex = optionsSelected.findIndex((v) => v === value);

      if (optionIndex >= 0) {
        optionsSelected.splice(optionIndex, 1);
      } else {
        optionsSelected.push(value);
      }

      setDevice({ ...device, [name]: optionsSelected });
    } else if (device[name] !== value) {
      setDevice((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setDevice((prevState) => ({ ...prevState, [name]: null }));
    }
  };

  const handleSubmit = async () => {
    try {
      await adminUser.api.addDevice(device);
      navigate(`/orders/${props.order.id}/devices`);
      props.onCloseModal();
      props.reloadOrder();
    } catch (err) {
      addToast(err.response?.data?.message ?? "An error has occured.");
    }
  };

  const getState = () => {
    const typologyKey = {
      new: "Neuf",
      asnew: "Certifié Apple",
      refurb: "Refurbished Premium",
    };

    // Add Device case
    const state_list = props.product_group?.products
      .map((product) => product.quality)
      .filter((quality) => quality !== null);

    const no_duplicate = [...new Set(state_list)];
    const french_wording = no_duplicate.map((s) => typologyKey[s]);
    const clean_state_list = french_wording.join(" ").toUpperCase();

    if (no_duplicate.length > 1) {
      return (
        <p className={styles.productGroupStateWording} style={{ color: "red" }}>
          {clean_state_list}
        </p>
      );
    }

    return <p className={styles.productGroupStateWording}>{clean_state_list}</p>;
  };

  const price = useMemo(() => {
    if (!props.product) return null;

    const currentCurrency = props.order.rent_currency;

    return formatMoney(
      props.product?.prices.find((price) => price.currency === currentCurrency)?.amount,
      currentCurrency,
    );
  }, [props.product, props.order]);

  return (
    <Drawer
      onClose={props.onCloseModal}
      title="Edit configuration"
      Actions={<Button variant="contained" color="primary" label="Save" onClick={handleSubmit} />}
      open={props.open}
    >
      <Layout direction="column" isScrollable>
        <div className={styles.infoComputerBox}>
          <figure className={styles.computerImageContainer}>
            {props.product_group && <img src={props.product_group.img_url} alt={props.product_group.name} />}
          </figure>
          <div className={styles.computerInfosBox}>
            <div className={styles.groupName}>{props.product_group?.name}</div>
            <p className={styles.groupDescription}>{props.product_group?.description}</p>
            <div className={styles.productGroupState}>
              <div className={styles.productGroupStateIcon}>
                <Icon className={styles.certificateIcon} icon={faCertificate} color="secondary" size="S" />
                <Icon className={styles.checkIcon} icon={faCheck} color="secondary" size="S" />
              </div>
              {getState()}
            </div>
          </div>
          <div className={styles.computerPriceBox}>
            <div className={styles.price}>{price}</div>
            <div>/months (without Tax)</div>
          </div>
        </div>
        <Layout direction="row" spacing={2} flexWrap="wrap">
          {productSpecs
            .filter(
              (spec) => spec.categories.includes(props.product_group?.category) && spec.applyTo.includes("DEVICE"),
            )
            .map((spec) => (
              <Layout direction="column" fullWidth>
                <Text variant="body2">{spec.label}</Text>
                <div className={styles.checkboxGroup}>
                  {spec.options(props.product_group?.category).map((option, i) => (
                    <ConfigCheckbox
                      option={option.label}
                      key={i}
                      name={spec.name}
                      isChecked={
                        !spec.multiple ? device[spec.name] === option.value : device[spec.name]?.includes(option.value)
                      }
                      select={() => handleChange(spec.name, option.value)}
                    />
                  ))}
                </div>
              </Layout>
            ))}
        </Layout>
      </Layout>
    </Drawer>
  );
};

export default AddDeviceModal;
