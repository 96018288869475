import type { Company } from "src/types/Company.type";

export const getMdmARR = (company: Company) => {
  if (!company) {
    return { current: 0, prediction: 0 };
  }
  
  const everyDevices =
    company.devices?.filter(
      (device) => device.deleted === false && ["COMPUTER", "SMARTPHONE"].includes(device.category),
    ) ?? [];
  
  const devicesMdm = everyDevices.filter((device) => device.device_mdm && !device.device_mdm.deleted) ?? [];
  
  const ratio = company.country === "United Kingdom" ? 1.18 : 1;
  const price = (company.mdm_unit_amount ?? 0) / 100;
  
  const current = devicesMdm.length * price * ratio * 12;
  const prediction = everyDevices.length * price * ratio * 12;
  
  return { current, prediction };
};

export const getAppsARR = (company: Company) => {
  if (!company) {
    return { current: 0, prediction: 0 };
  }
  
  const everyDevices =
    company.devices?.filter(
      (device) => device.deleted === false && ["COMPUTER", "SMARTPHONE"].includes(device.category),
    ) ?? [];
  
  const devicesMdmWithApps =
    everyDevices.filter(
      (device) =>
        device.device_mdm && !device.device_mdm.deleted && ["ENROLLED", "INSTALLED"].includes(device.device_mdm.status),
    ) ?? [];
  
  const ratio = company.country === "United Kingdom" ? 1.18 : 1;
  const apps = company.company_mdm?.apps ?? [];
  
  const totalAppsPrice = apps.reduce((acc, app) => {
    let price = app?.price ?? 0;
    if (price === 0 && app?.name && app.name.toLowerCase().includes("bitdefender")) {
      price = 200;
    }
    return acc + (price / 100) * ratio;
  }, 0);
  
  const current = totalAppsPrice * devicesMdmWithApps.length * 12;
  const prediction = totalAppsPrice * everyDevices.length * 12;
  
  return { current, prediction };
};
