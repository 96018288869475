/**
 * Constants and utility functions for handling MDM coupons
 */

export const REGIONS = {
  UK: "UK",
  EU: "EU",
} as const;

export type Region = typeof REGIONS[keyof typeof REGIONS];

/**
 * Determines the region based on the country
 */
export const getRegionFromCountry = (country: string): Region => {
  return country.toLowerCase() === "united kingdom" ? REGIONS.UK : REGIONS.EU;
};

/**
 * Gets the coupon ID for a specific number of months and region
 */
export const getCouponId = (months: number, region: Region): string => {
  // Try both formats - singular for 1 month, plural for others
  const envKey = months === 1 
    ? `REACT_APP_STRIPE_COUPON_1_MONTH_FREE_${region}`
    : `REACT_APP_STRIPE_COUPON_${months}_MONTHS_FREE_${region}`;
  
  const couponId = import.meta.env[envKey];
  
  if (!couponId) {
    console.warn(`No coupon ID found for ${months} months in ${region} region (${envKey})`);
  }
  
  return couponId || "";
};

/**
 * Gets the display text for a coupon based on its ID and region
 */
export const getMonthsText = (couponId: string | null, region: Region): string => {
  if (!couponId) return "";
    
  const oneMonth = getCouponId(1, region);
  const twoMonths = getCouponId(2, region);
  const threeMonths = getCouponId(3, region);
  
  if (couponId === oneMonth) return "1 free month";
  if (couponId === twoMonths) return "2 free months";
  if (couponId === threeMonths) return "3 free months";
  return "";
};

/**
 * Gets the number of months from a coupon ID
 */
export const getMonthsFromCouponId = (couponId: string | null, region: Region): number => {
  if (!couponId) return 0;
  
  const oneMonth = getCouponId(1, region);
  const twoMonths = getCouponId(2, region);
  const threeMonths = getCouponId(3, region);
  
  if (couponId === oneMonth) return 1;
  if (couponId === twoMonths) return 2;
  if (couponId === threeMonths) return 3;
  return 0;
}; 