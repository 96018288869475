import { Suspense, useMemo, useState } from "react";
import { Route, Routes, useLocation, useParams } from "react-router";
import { Layout } from "@fleet.co/tarmac";
import { useSuspenseQuery } from "@tanstack/react-query";

import CompanyInfoTabs from "../sections/company/CompanyInfoTabs";
import { CompanyGeneralInfo } from "../sections/company/CompanyGeneralInfo";
import CompanyAddressesInfo from "../sections/company/CompanyAddressesInfo";
import CompanyUsersInfo from "../sections/company/CompanyUsersInfo";
import CompanyDevicesInfo from "../sections/company/CompanyDevicesInfo";
import CompanyFiles from "../sections/company/CompanyFiles";
import CompanyFundingInfo from "../sections/company/CompanyFundingInfo";
import CompanyPricing from "../../pages/company/CompanyPricing";
import Breadcrumb from "../common/Breadcrumb";
import CompanyContext from "../../contexts/CompanyContext";
import CompanyOrdersTable from "../tables/companyOrders/CompanyOrdersTable";
import { companyQueries } from "../sections/company/queries/companyQueries";
import LoadingSpinner from "../common/LoadingSpinner";
import ErrorBoundary from "../utils/ErrorBoudaries";

function CompanyInfoContent() {
  const { id } = useParams();
  const location = useLocation();
  const [currentAction, setCurrentAction] = useState(null);
  const [modalOpened, setModalOpened] = useState({
    type: "",
  });

  const { data: company } = useSuspenseQuery(companyQueries.detail(Number(id)));

  const [showFileUpload, setShowFileUpload] = useState(false);

  // returns a list of {name, link} objects, that are used in the Breadcrumb component
  const generateBC = () => {
    const res = [{ name: "Companies" }];

    // Main company page : no links
    if (location.pathname.endsWith(`${company.id}`)) {
      res.push({ name: company.name });

      return res;
    }

    // Sub pages
    res.push({ name: company.name, link: `/companies/${company.id}` });

    if (location.pathname.endsWith("devices")) {
      res.push({ name: "Devices" });
    } else if (location.pathname.endsWith("files")) {
      res.push({ name: "Files" });
    } else if (location.pathname.endsWith("users")) {
      res.push({ name: "Users" });
    } else if (location.pathname.endsWith("addresses")) {
      res.push({ name: "Addresses" });
    } else if (location.pathname.endsWith("orders")) {
      res.push({ name: "Orders" });
    } else if (location.pathname.endsWith("funding")) {
      res.push({ name: "Funding" });
    } else if (location.pathname.endsWith("pricing")) {
      res.push({ name: "Pricing" });
    }

    return res;
  };

  const companyContextValue = useMemo(
    () => ({
      company,
    }),
    [company],
  );

  return (
    <CompanyContext.Provider value={companyContextValue}>
      <Layout direction="column" spacing={1}>
        <Breadcrumb nameAndLinkArray={generateBC()} />

        <CompanyInfoTabs
          triggerAction={setCurrentAction}
          currentAction={currentAction}
          setModalOpened={setModalOpened}
          setShowFileUpload={setShowFileUpload}
        />
        <Routes>
          <Route path="/" element={<CompanyGeneralInfo />} />
          <Route
            path="/addresses"
            element={<CompanyAddressesInfo setModalOpened={setModalOpened} modalOpened={modalOpened} />}
          />

          <Route path="/orders" element={<CompanyOrdersTable />} />

          <Route
            path="/funding"
            element={<CompanyFundingInfo setModalOpened={setModalOpened} modalOpened={modalOpened} />}
          />

          <Route path="/devices" element={<CompanyDevicesInfo />} />

          <Route
            path="/users"
            element={
              <CompanyUsersInfo
                setShowAddUser={(show) => setCurrentAction(show ? "add_user" : "")}
                showAddUser={currentAction === "add_user"}
              />
            }
          />

          <Route
            path="/files"
            element={<CompanyFiles showFileUpload={showFileUpload} closeUpload={() => setShowFileUpload(false)} />}
          />
          
          <Route path="/pricing" element={<CompanyPricing />} />
        </Routes>
      </Layout>
    </CompanyContext.Provider>
  );
}

export default function CompanyInfo() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingSpinner />}>
        <CompanyInfoContent />
      </Suspense>
    </ErrorBoundary>
  );
}
