import { COUNTRIES } from "src/common/i18n-consts";
import { leasersList } from "../data/leasersList";

const getCompanyCountryCode = (order) => {
  const countryCodes = {
    [COUNTRIES.SPAIN]: "es",
    [COUNTRIES.GERMANY]: "de",
    [COUNTRIES.UNITED_KINGDOM]: "gb",
    [COUNTRIES.FRANCE]: "fr",
    [COUNTRIES.UNITED_STATES]: "us",
  };

  if (Object.keys(countryCodes).includes(order.company.country)) {
    return countryCodes[order.company.country];
  }

  return "eu";
};

const calculateLeaserRate = (leaser, is_AFS) => {
  let leaserRate = null;

  if (leaser === "Grenke") {
    leaserRate = 3.17;
  } else if (leaser === "NBB Lease") {
    leaserRate = 3.113;
  } else if (leaser === "BNP" && is_AFS) {
    leaserRate = 2.896;
  } else if (leaser === "BNP") {
    leaserRate = 3.21;
  } else if (leaser === "Leasecom") {
    leaserRate = 3.1565;
  } else if (leaser === "Locam") {
    leaserRate = 3.156;
  } else if (leaser === "Franfinance") {
    leaserRate = 3.12;
  } else if (leaser === "Peac France") {
    leaserRate = 3.2;
  } else if (leaser === "CF Corporate Finance LTD") {
    leaserRate = 3.2;
  } else if (leaser === "Grenke España") {
    leaserRate = 3.16;
  } else if (leaser === "Grenke Italy") {
    leaserRate = 3.325;
  } else if (leaser === "Grenke Netherlands") {
    leaserRate = 3.46;
  } else if (leaser === "SG España") {
    leaserRate = 3.37;
  } else if (leaser === "Siemens Spain") {
    leaserRate = 3.17;
  } else if (leaser === "HPFS Spain") {
    leaserRate = 3.17;
  } else if (leaser === "Iberent Spain") {
    leaserRate = 3.2;
  } else if (leaser === "DLL España") {
    leaserRate = 3.2;
  } else if (leaser === "Peac Germany") {
    leaserRate = 3.19;
  } else if (leaser === "DLL Germany") {
    leaserRate = 3.15;
  } else if (leaser === "Grenke Portugal") {
    leaserRate = 3.16;
  } else if (leaser === "Upfront") {
    leaserRate = 100;
  }

  return leaserRate;
};

const calculateGrossSales = (order) => {
  if (typeof order.devices === "undefined" || !order.devices) {
    return "N/A";
  }

  const totalSalesPerMonth = order.devices.reduce((acc, d) => acc + parseFloat(d.rent), 0);

  const discountedTotal = totalSalesPerMonth - Number(order.offer_discount);
  const grossSales = discountedTotal * order.contract_duration;

  return grossSales.toFixed(2);
};

const convertPrice = (amount, from_currency, to_currency) => {
  if (from_currency === to_currency) {
    return amount;
  }

  if (from_currency === "EUR" && to_currency === "GBP") {
    return amount * 0.87;
  }

  if (from_currency === "GBP" && to_currency === "EUR") {
    return amount * 1.15;
  }

  return amount;
};

const calculateDeviceCost = (device, rent_currency) => {
  // if device.acquisition_currency if not the same as rent_currency, apply a conversion
  const shippingCost = parseFloat(device.shipping_fee || 0);
  const hardwareCost = parseFloat(device.acquisition_price || 0);
  const warrantyCost = parseFloat(device.warranty_cost || 5);

  const device_cost = hardwareCost + warrantyCost + shippingCost;

  return convertPrice(device_cost, device.acquisition_currency, rent_currency);
};

const calculateGrossMarginAmount = (device, order) => {
  if (typeof device === "undefined" || !device || !device.acquisition_price || device.acquisition_price === "0") {
    return null;
  }

  const sumDevicesRent = order.devices.reduce((a, b) => a + parseFloat(b.rent), 0);
  const deviceShare = device.rent / sumDevicesRent;
  const monthlyDeviceRent = order.rent * deviceShare;
  const leaserRate = parseFloat(order.leaser_rate || 3.2);

  const netRevenue = (100 * monthlyDeviceRent) / leaserRate;

  return netRevenue - calculateDeviceCost(device, order.rent_currency);
};

const calculateGrossMarginPercentage = (device, order) => {
  const grossMarginAmount = calculateGrossMarginAmount(device, order);

  if (grossMarginAmount === null) {
    return null;
  }

  const sumDevicesRent = order.devices.reduce((a, b) => a + parseFloat(b.rent), 0);
  const deviceShare = device.rent / sumDevicesRent;
  const monthlyDeviceRent = order.rent * deviceShare;

  const totalSales = monthlyDeviceRent * parseFloat(order.contract_duration);

  const grossMarginPercentage = grossMarginAmount > 0 ? (grossMarginAmount / totalSales) * 100 : 0;

  return grossMarginPercentage.toFixed(2);
};

const calculateContributionMargin = (device, order) => {
  if (typeof device === "undefined" || !device || !device.acquisition_price || device.acquisition_price === "0") {
    return null;
  }

  const sumDevicesRent = order.devices.reduce((a, b) => a + parseFloat(b.rent), 0);
  const deviceShare = device.rent / sumDevicesRent;
  const monthlyDeviceRent = order.rent * deviceShare;
  const leaserRate = parseFloat(order.leaser_rate || 3.2);

  const netRevenue = (100 * monthlyDeviceRent) / leaserRate;
  // Leaser Revenue
  const totalSales = monthlyDeviceRent * parseFloat(order.contract_duration);

  const cm = netRevenue - calculateDeviceCost(device, order.rent_currency);

  const cmRatio = cm > 0 ? (cm / totalSales) * 100 : 0;

  return cmRatio.toFixed(2);
};

const calculateOrderSubTotalPricing = (order) => {
  const totalDevices = order.devices.slice() || [];

  const totalRent = totalDevices.reduce((sum, device) => sum + parseFloat(device.rent), 0);

  const totalRentToReturn = totalRent.toFixed(2);

  return totalRentToReturn;
};

const calculateCompanyOfferDiscount = (order) => {
  const totalRent = calculateOrderSubTotalPricing(order);

  let offerDiscount = 0;

  if (order.company_offer) {
    if (order.company_offer.type === "MONTH_OFFERED") {
      offerDiscount = totalRent / order.contract_duration;
    } else if (order.company_offer.type === "2_MONTHS_OFFERED") {
      offerDiscount = (2 * totalRent) / order.contract_duration;
    } else if (order.company_offer.type === "3_MONTHS_OFFERED") {
      offerDiscount = (3 * totalRent) / order.contract_duration;
    }
  }

  return offerDiscount.toFixed(2);
};

const calculateReferralDiscount = (order) => {
  const totalRent = calculateOrderSubTotalPricing(order);

  let referralDiscount = 0;

  if (order.referrer_id) {
    referralDiscount = (totalRent / order.contract_duration).toFixed(2);
  }

  return referralDiscount;
};

const calculateOrderDiscount = (order) => {
  const totalRent = calculateOrderSubTotalPricing(order);
  let orderDiscount = 0;

  if (order.month_offered) {
    orderDiscount = (totalRent / order.contract_duration).toFixed(2);
  }

  return orderDiscount;
};

const calculateOrderTotalPricing = (order) => {
  const totalRent = calculateOrderSubTotalPricing(order);
  const orderDiscount = calculateOrderDiscount(order);
  const companyOfferDiscount = calculateCompanyOfferDiscount(order);
  const referralDiscount = calculateReferralDiscount(order);
  const orderTotal = (totalRent - orderDiscount - companyOfferDiscount - referralDiscount).toFixed(2);

  return orderTotal;
};

/**
 * @summary Calculate and return the total price of the device after applying all discounts:
 *            - The Order Discount applied manually in the BO, offering 1 month.
 *            - The Offer Discount for special promotions, offering 1, 2 or 3 months.
 *            - The Referral Discount when a client is sponsored by another client, offering 1 month.
 *          This function follow the same logic as the calculateOrderTotalPricing() function but for a single device.
 *
 * @param {object} device - The device object
 *
 * @returns {string} orderTotal
 */
const calculateDeviceOrderTotalPricing = (device) => {
  let orderDiscount = 0;
  let offerDiscount = 0;
  let referralDiscount = 0;
  let orderTotal;

  if (device?.order?.contract_duration) {
    if (device.order.month_offered) {
      orderDiscount = (device.rent / device.order.contract_duration).toFixed(2);
    }

    if (device.order.company_offer) {
      if (device.order.company_offer.type === "MONTH_OFFERED") {
        offerDiscount = device.rent / device.order.contract_duration;
      } else if (device.order.company_offer.type === "2_MONTHS_OFFERED") {
        offerDiscount = (2 * device.rent) / device.order.contract_duration;
      } else if (device.order.company_offer.type === "3_MONTHS_OFFERED") {
        offerDiscount = (3 * device.rent) / device.order.contract_duration;
      }
    }
    offerDiscount = offerDiscount.toFixed(2);

    if (device.order.referrer_id) {
      referralDiscount = (device.rent / device.order.contract_duration).toFixed(2);
    }
    orderTotal = (device.rent - orderDiscount - offerDiscount - referralDiscount).toFixed(2);
  }

  return orderTotal;
};

const sortLeaserScoringOrder = (order) => {
  const sortByTotal = (l1, l2) => l2.total - l1.total;

  order.leaserScoring?.sort(sortByTotal);

  return order;
};

const getLeaserList = (order) => {
  if (!order) return [];

  const countryCode = getCompanyCountryCode(order);

  const sortByTotal = (l1, l2) => l2.total - l1.total;

  const completeLeaserInfo = leasersList.map((leaser) => {
    const thisScoring = order.leaserScoring?.find((l) => l.leaser === leaser.name);
    const total = thisScoring?.total || 0;
    const criteria = thisScoring?.criteria || [];

    return { ...leaser, total, criteria };
  });

  return completeLeaserInfo.sort(sortByTotal).filter((l) => {
    if (countryCode === "us") {
      return l.country === "eu";
    }

    return l.country === countryCode;
  });
};

const getLeaserLabel = (leaserName) => {
  const selectedLeaser = leasersList.find((leaser) => leaser.name === leaserName);

  return selectedLeaser?.label;
};

const getContractEndDate = (order) => {
  const startDate = new Date(order.contract_start_date);
  const endingDate = startDate.setMonth(startDate.getMonth() + order.contract_duration);

  return new Date(endingDate);
};

export {
  calculateLeaserRate,
  calculateOrderDiscount,
  calculateCompanyOfferDiscount,
  calculateReferralDiscount,
  calculateOrderSubTotalPricing,
  calculateOrderTotalPricing,
  calculateDeviceOrderTotalPricing,
  getContractEndDate,
  calculateGrossSales,
  calculateDeviceCost,
  calculateGrossMarginPercentage,
  calculateGrossMarginAmount,
  calculateContributionMargin,
  sortLeaserScoringOrder,
  getLeaserList,
  convertPrice,
  getLeaserLabel,
};
