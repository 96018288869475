import React, { useCallback, useContext, useEffect, useState } from "react";
import { DataGrid } from "@fleet.co/tarmac";
import CompanyContext from "../../../contexts/CompanyContext";
import CopyButton from "../../../tools/CopyButton";
import FormatDate from "../../../tools/FormatDate";
import OrderStatusNiceName from "../../blocks/OrderStatusNiceName";
import ActionsCell from "./cells/ActionsCell";
import { calculateGrossSales } from "../../../tools/OrderTools";
import { formatMoney } from "../../../helpers/formatHelpers";
import StatusCustomFilter from "./customFilters/StatusCustomFilter";
import CommentCell from "./cells/CommentCell";
import PurchaserCell from "./cells/PurchaserCell";

const validatedStatuses = [
  "READY_FOR_PURCHASE",
  "PURCHASED",
  "SHIPPED",
  "DELIVERED",
  "BDL_SENT",
  "RECEIVED",
  "FINALIZED",
  "CLOSED",
  "INACTIVE",
];

const qualifiedStatuses = [
  "LEAD_AGREEMENT",
  "LEASER_AGREEMENT",
  "LEASER_REQUESTED",
  "LEASER_MORE_DOCUMENTS",
  "LEASER_DISAGREEMENT",
  "LEAD_SIGNING",
];

const CompanyOrdersTable = () => {
  const { company } = useContext(CompanyContext);
  const [companyOrders, setCompanyOrders] = useState(company.orders);
  const [statusFilter, setStatusFilter] = useState(null);

  const shouldDisplayCommentTab = company.orders.some((order) => order.comment);

  const getPurchaser = useCallback(
    (order) => company.members.find((member) => member.id === order.purchaser_id),
    [company.members],
  );

  const columns = [
    {
      field: "status",
      headerName: "Status",
      actionType: "custom",
      CustomFilterComponent: StatusCustomFilter,
      customFilterComponentProps: {
        onChange: (value) => setStatusFilter(value),
        value: statusFilter,
      },
      renderCell: ({ row: order }) => <OrderStatusNiceName status={order.status} />,
      minWidth: 150,
    },
    {
      field: "leaser",
      headerName: "Leaser",
      actionType: "sort",
      valueGetter: ({ row: order }) => order.leaser,
    },
    {
      field: "contract_number",
      headerName: "Contract Number",
      actionType: "sort",
    },
    {
      field: "order_number",
      headerName: "Order N°",
      actionType: "sort",
      valueGetter: ({ row: order }) => order.order_number,
      renderCell: ({ row: order }) => <CopyButton value={order.order_number}>{order.order_number}</CopyButton>,
    },
    {
      field: "purchaser",
      headerName: "Purchaser",
      valueGetter: ({ row: order }) => getPurchaser(order),
      renderCell: PurchaserCell,
    },
    {
      field: "purchase_date",
      headerName: "Purchase Date",
      actionType: "sort",
      renderCell: ({ row: order }) => <FormatDate date={order.purchase_date} />,
    },
    {
      field: "contract_start_date",
      headerName: "Contract Start Date",
      actionType: "sort",
      renderCell: ({ row: order }) => <FormatDate date={order.contract_start_date} />,
    },
    {
      field: "contract_end_date",
      headerName: "Contract End Date",
      actionType: "sort",
      renderCell: ({ row: order }) => <FormatDate date={order.contract_end_date} />,
    },
    {
      field: "rent",
      headerName: "Rent",
      actionType: "sort",
      renderCell: ({ row: order }) => formatMoney(order.rent, order.rent_currency),
    },
    {
      field: "gross_revenue",
      headerName: "Gross Revenue",
      actionType: "sort",
      valueGetter: ({ row: order }) => formatMoney(calculateGrossSales(order), order.rent_currency),
    },
    {
      field: "num_devices",
      headerName: "Device Count",
      actionType: "sort",
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: ActionsCell,
      minWidth: 200,
    },
  ];

  if (shouldDisplayCommentTab) {
    columns.splice(columns.length - 1, 0, {
      field: "comment",
      headerName: "Comment",
      actionType: "custom",
      renderCell: CommentCell,
    });
  }

  useEffect(() => {
    let filteredOrders = company.orders;

    switch (statusFilter) {
      case "validated":
        filteredOrders = company.orders.filter((order) => validatedStatuses.includes(order.status));
        break;
      case "qualified_lead":
        filteredOrders = company.orders.filter((order) => qualifiedStatuses.includes(order.status));
        break;
      case "carts":
        filteredOrders = company.orders.filter((order) => order.status === "UNQUALIFIED_LEAD");
        break;
      case "failed":
        filteredOrders = company.orders.filter((order) => order.status === "FAILED");
        break;
      default:
        filteredOrders = company.orders.filter((order) => order.status !== "UNQUALIFIED_LEAD");
        break;
    }

    setCompanyOrders(filteredOrders);
  }, [statusFilter, company.orders]);

  return <DataGrid rows={companyOrders} columns={columns} />;
};

export default CompanyOrdersTable;
