import React, { useState, useEffect, useContext, useCallback } from "react";
import { Layout, TextField, Text, Button } from "@fleet.co/tarmac";
import CompanyContext from "../../contexts/CompanyContext";
import API from "../../tools/API";
import { formatMoney } from "../../helpers/formatHelpers";
import UserContext from "../../tools/UserContext";

// List of authorized users who can access company pricing tables
export const authorizedCompanyPricingUsersEmails = [
  "guy.houot@fleet.co",
  "robin.marillia@fleet.co",
  "armelle.casanova@fleet.co",
  "geoffroy.danest@fleet.co",
  "developer@fleet.co",
  "developer+deploy@fleet.co",
  "kevin.blondel.ext@fleet.co"
];

// Currency mapping by country code and full country name
const COUNTRY_CURRENCY_MAP: Record<string, string> = {
  // Full country names
  "UNITED STATES": "USD",
  "USA": "USD",
  "FRANCE": "EUR",
  "GERMANY": "EUR",
  "ITALY": "EUR",
  "SPAIN": "EUR",
  "GREAT BRITAIN": "GBP",
  "UNITED KINGDOM": "GBP",
  "ENGLAND": "GBP", 
  "JAPAN": "JPY",
  "CHINA": "CNY",
  "CANADA": "CAD",
  "AUSTRALIA": "AUD",
  "NEW ZEALAND": "NZD",
  "SWITZERLAND": "CHF",
  "SWEDEN": "SEK",
  "NORWAY": "NOK",
  "DENMARK": "DKK",
  "POLAND": "PLN",
  "CZECH REPUBLIC": "CZK",
  "CZECHIA": "CZK",
  "BRAZIL": "BRL",
  "RUSSIA": "RUB",
  "INDIA": "INR",
  "SOUTH AFRICA": "ZAR",
  "SINGAPORE": "SGD",
  
  // European countries using EUR
  "AUSTRIA": "EUR",
  "BELGIUM": "EUR",
  "BULGARIA": "BGN",
  "CROATIA": "EUR",
  "CYPRUS": "EUR",
  "ESTONIA": "EUR",
  "FINLAND": "EUR",
  "GREECE": "EUR",
  "HUNGARY": "HUF",
  "IRELAND": "EUR",
  "LATVIA": "EUR",
  "LITHUANIA": "EUR",
  "LUXEMBOURG": "EUR",
  "MALTA": "EUR",
  "NETHERLANDS": "EUR",
  "PORTUGAL": "EUR",
  "ROMANIA": "RON",
  "SLOVAKIA": "EUR",
  "SLOVENIA": "EUR",
  
  // Other common countries
  "MEXICO": "MXN",
  "ARGENTINA": "ARS",
  "CHILE": "CLP",
  "COLOMBIA": "COP",
  "PERU": "PEN",
  "VENEZUELA": "VES",
  "TURKEY": "TRY",
  "EGYPT": "EGP",
  "NIGERIA": "NGN",
  "KENYA": "KES",
  "SAUDI ARABIA": "SAR",
  "UNITED ARAB EMIRATES": "AED",
  "UAE": "AED",
  "PAKISTAN": "PKR",
  "BANGLADESH": "BDT",
  "THAILAND": "THB",
  "MALAYSIA": "MYR",
  "INDONESIA": "IDR",
  "PHILIPPINES": "PHP",
  "VIETNAM": "VND",
  "SOUTH KOREA": "KRW",
  "KOREA": "KRW",
  "TAIWAN": "TWD",
  "HONG KONG": "HKD"
  // Add more as needed
};

const getCurrencyFromCountry = (countryName?: string): string => {
  if (!countryName) return "USD";
  const normalizedCountryName = countryName.toUpperCase().replace(/[^A-Z0-9]/g, "");
  const normalizedMap: Record<string, string> = {};
  
  Object.keys(COUNTRY_CURRENCY_MAP).forEach(key => {
    normalizedMap[key.replace(/[^A-Z0-9]/g, "")] = COUNTRY_CURRENCY_MAP[key] || "USD";
  });
  
  // Try to find currency in the normalized map first
  if (normalizedMap[normalizedCountryName]) {
    return normalizedMap[normalizedCountryName];
  }
  
  // Then try the original map with uppercase country name
  const currencyFromMap = COUNTRY_CURRENCY_MAP[countryName.toUpperCase()];
  if (currencyFromMap) {
    return currencyFromMap;
  }

  // Default to USD if not found
  return "USD";
};

interface PricingPlan {
  id: number;
  company_id: number;
  product_type: string;
  plan_name: string;
  stripe_price_id: string;
  price_amount: number;
  annual_price_amount: number;
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

const PRODUCT_TYPES = [
  { label: "Bitdefender", value: "BITDEFENDER" },
  { label: "Keeper", value: "KEEPER" }
];

interface AdminUser {
  email: string;
  [key: string]: any;
}

interface PricingConfig {
  monthly: number;
  annual: number;
}

interface ProductPricing {
  [key: string]: PricingConfig;
}

interface MinimumPrices {
  [key: string]: ProductPricing;
  BITDEFENDER: ProductPricing;
  KEEPER: ProductPricing;
  CHECK_POINT: ProductPricing;
}

const MINIMUM_PRICES: MinimumPrices = {
  BITDEFENDER: {
    "BitDefender BASIC": { monthly: 1.2, annual: 1.2 },
    "BitDefender ADVANCED": { monthly: 2.7, annual: 2.7 },
    "BitDefender SECURITY +": { monthly: 3.0, annual: 3.0 }
  },
  KEEPER: {
    "Keeper ENTREPRISE": { monthly: 4.2, annual: 4.2 },
    "Keeper SECURITY +": { monthly: 7.0, annual: 7.0 }
  },
  CHECK_POINT: {
    "Check Point": { monthly: 7.0, annual: 7.0 },
    Standard: { monthly: 7.0, annual: 7.0 }
  }
};

const CompanyPricing: React.FC = () => {
  const { company } = useContext(CompanyContext);
  const { user: adminUser } = useContext(UserContext) as { user: AdminUser | null };
  const [pricingPlans, setPricingPlans] = useState<PricingPlan[]>([]);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    product_type: "",
    plan_name: "",
    stripe_price_id: "",
    price_amount: "",
    annual_price_amount: ""
  });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [planToDelete, setPlanToDelete] = useState<number | null>(null);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<"success" | "error">("success");
  const [showAddModal, setShowAddModal] = useState(false);
  const [editingPlanId, setEditingPlanId] = useState<number | null>(null);
  const [originalPlan, setOriginalPlan] = useState<PricingPlan | null>(null);

  const fetchPricingPlans = useCallback(async () => {
    if (!company?.id) return;
    
    try {
      setLoading(true);
      const response = await API.get(`/company/${company.id}/pricing`);
      const plans = Array.isArray(response.data) ? response.data : [];
      setPricingPlans(plans);
    } catch (error) {
      console.error("Failed to fetch pricing plans:", error);
      setToastMessage("Failed to load pricing plans");
      setToastType("error");
      setPricingPlans([]); // Set to empty array on error
    } finally {
      setLoading(false);
    }
  }, [company?.id, setLoading, setPricingPlans, setToastMessage, setToastType]);

  useEffect(() => {
    if (company?.id) {
      fetchPricingPlans();
    }
  }, [company, fetchPricingPlans]);

  const handleFormChange = (field: string, value: string) => {
    setFormValues({
      ...formValues,
      [field]: value
    });
  };

  const validatePrices = (values: typeof formValues, plan: PricingPlan | null): string | null => {
    if (!plan) return null;
    
    const minimumPrices = MINIMUM_PRICES[plan.product_type]?.[plan.plan_name];
    if (!minimumPrices) return null;

    const monthlyPrice = parseFloat(values.price_amount);
    const annualPrice = parseFloat(values.annual_price_amount);

    if (monthlyPrice < minimumPrices.monthly) {
      return `Monthly price must be at least ${minimumPrices.monthly} ${getCurrencyFromCountry(company?.country)}`;
    }

    if (annualPrice < minimumPrices.annual) {
      return `Annual price must be at least ${minimumPrices.annual} ${getCurrencyFromCountry(company?.country)}`;
    }

    return null;
  };

  const handleAddPlan = async () => {
    if (!company?.id) return;
    
    try {
      setLoading(true);

      // Validate minimum prices
      const validationError = validatePrices(formValues, originalPlan);
      if (validationError) {
        setToastMessage(validationError);
        setToastType("error");
        setLoading(false);
        return;
      }
      
      if (editingPlanId) {
        // For updates, use PATCH to update the existing plan
        await API.patch(`/company/${company.id}/pricing/${editingPlanId}`, {
          price_amount: parseFloat(formValues.price_amount),
          annual_price_amount: parseFloat(formValues.annual_price_amount),
          updated_at: new Date().toISOString()
        });
        
        setToastMessage("Pricing plan updated successfully");
      } else {
        // Add new plan
        await API.post(`/company/${company.id}/pricing`, {
          product_type: formValues.product_type,
          plan_name: formValues.plan_name,
          stripe_price_id: formValues.stripe_price_id,
          price_amount: parseFloat(formValues.price_amount),
          annual_price_amount: parseFloat(formValues.annual_price_amount)
        });
        setToastMessage("Pricing plan added successfully");
      }
      
      setToastType("success");
      setFormValues({
        product_type: "",
        plan_name: "",
        stripe_price_id: "",
        price_amount: "",
        annual_price_amount: ""
      });
      setShowAddModal(false);
      setEditingPlanId(null);
      setOriginalPlan(null);
      fetchPricingPlans();
    } catch (error) {
      const actionType = editingPlanId ? "update" : "add";
      console.error(`Failed to ${actionType} pricing plan:`, error);
      setToastMessage(`Failed to ${actionType} pricing plan`);
      setToastType("error");
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async () => {
    if (!company?.id || !planToDelete) return;
    
    try {
      setLoading(true);
      await API.delete(`/company/${company.id}/pricing/${planToDelete}`);
      setToastMessage("Pricing plan deactivated successfully");
      setToastType("success");
      fetchPricingPlans();
    } catch (error) {
      console.error("Failed to deactivate pricing plan:", error);
      setToastMessage("Failed to deactivate pricing plan");
      setToastType("error");
    } finally {
      setLoading(false);
      setShowDeleteDialog(false);
      setPlanToDelete(null);
    }
  };

  // Display the toast if it exists
  useEffect(() => {
    if (toastMessage) {
      const timer = setTimeout(() => {
        setToastMessage(null);
      }, 3000);
      
      return () => clearTimeout(timer);
    }
    // No cleanup needed when there's no toast message
    return () => {}; // Empty function is cleaner than returning null
  }, [toastMessage]);
  const closeAddModal = () => {
    setShowAddModal(false);
    setEditingPlanId(null);
    setOriginalPlan(null);
  };

  const openEditModal = (plan: PricingPlan) => {
    setEditingPlanId(plan.id);
    setOriginalPlan(plan);
    setFormValues({
      product_type: plan.product_type,
      plan_name: plan.plan_name,
      stripe_price_id: plan.stripe_price_id,
      price_amount: plan.price_amount.toString(),
      annual_price_amount: plan.annual_price_amount.toString()
    });
    setShowAddModal(true);
  };

  // Check if user is authorized to view pricing table
  const userEmail = adminUser?.email || "";
  if (!authorizedCompanyPricingUsersEmails.includes(userEmail)) {
    return <div>You are not authorized to access this page</div>;
  }

  return (
    <Layout direction="column" spacing={3}>
      <div>
        <Text variant="h2">App Pricing Plans</Text>
        <Text variant="body2">
          Manage custom pricing plans for different products for this company.
          These prices will override the default pricing when creating subscriptions.
        </Text>
      </div>
      <Layout direction="column" spacing={2}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd" }}>Product</th>
              <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd" }}>Plan Name</th>
              <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd" }}>Stripe Price ID</th>
              <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd" }}>Price</th>
              <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd" }}>Annual Price</th>
              <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd" }}>Created</th>
              <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd" }}>Status</th>
              <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {(() => {
              // Loading state
              if (loading) {
                return (
                  <tr>
                    <td colSpan={7} style={{ textAlign: "center", padding: "16px" }}>Loading...</td>
                  </tr>
                );
              }
              
              // Empty state
              if (!pricingPlans || pricingPlans.length === 0) {
                return (
                  <tr>
                    <td colSpan={7} style={{ textAlign: "center", padding: "16px" }}>No pricing plans found</td>
                  </tr>
                );
              }
              
              // Plans list
              return pricingPlans.map(plan => (
                <tr key={plan.id}>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                    {PRODUCT_TYPES.find(p => p.value === plan.product_type)?.label || plan.product_type}
                  </td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{plan.plan_name}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{plan.stripe_price_id}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                    {formatMoney(plan.price_amount, getCurrencyFromCountry(company?.country))}
                  </td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                    {formatMoney(plan.annual_price_amount, getCurrencyFromCountry(company?.country))}
                  </td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{new Date(plan.created_at).toLocaleDateString()}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{plan.is_active ? "Active" : "Inactive"}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                    <div style={{ display: "flex", gap: "8px" }}>
                      <button
                        type="button"
                        onClick={() => openEditModal(plan)}
                        style={{ 
                          background: "transparent", 
                          border: "1px solid #1976d2",
                          borderRadius: "4px",
                          padding: "4px 8px",
                          cursor: "pointer",
                          color: "#1976d2",
                          fontSize: "12px"
                        }}
                        title="Edit Plan"
                      >
                        Edit
                      </button>
                    </div>
                  </td>
                </tr>
              ));
            })()}
          </tbody>
        </table>
      </Layout>

      {showAddModal && (
        <div style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000
        }}>
          <div style={{
            backgroundColor: "white",
            padding: "24px",
            borderRadius: "4px",
            maxWidth: "500px",
            width: "100%"
          }}>
            <h2>{editingPlanId ? "Edit Pricing Plan" : "Add New Pricing Plan"}</h2>
            <Layout direction="column" spacing={2} sx={{ marginTop: "16px" }}>      
              <TextField
                label={`Monthly Price (${getCurrencyFromCountry(company?.country)})`}
                type="number"
                value={formValues.price_amount}
                onChange={(e) => handleFormChange("price_amount", e.target.value)}
                placeholder="e.g., 9.99"
                fullWidth
                helperText={originalPlan ? `Minimum: ${MINIMUM_PRICES[originalPlan.product_type]?.[originalPlan.plan_name]?.monthly || 0} ${getCurrencyFromCountry(company?.country)}` : ""}
              />
              <TextField
                label={`Annual Price (${getCurrencyFromCountry(company?.country)})`}
                type="number"
                value={formValues.annual_price_amount}
                onChange={(e) => handleFormChange("annual_price_amount", e.target.value)}
                placeholder="e.g., 99.99" 
                fullWidth
                helperText={originalPlan ? `Minimum: ${MINIMUM_PRICES[originalPlan.product_type]?.[originalPlan.plan_name]?.annual || 0} ${getCurrencyFromCountry(company?.country)}` : ""}
              />
              
              <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px", marginTop: "16px" }}>
                <Button 
                  variant="outlined"
                  color="secondary"
                  onClick={closeAddModal}
                  label="Cancel"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddPlan}
                  disabled={!formValues.price_amount || loading}
                  label={(() => {
                    if (loading) return "Saving...";
                    if (editingPlanId) return "Save Changes";
                    return "Add Plan";
                  })()}
                />
              </div>
            </Layout>
          </div>
        </div>
      )}

      {/* Delete Confirmation Dialog - Using a simple modal */}
      {showDeleteDialog && (
        <div style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000
        }}>
          <div style={{
            backgroundColor: "white",
            padding: "24px",
            borderRadius: "4px",
            maxWidth: "500px",
            width: "100%"
          }}>
            <h2>Confirm Deactivation</h2>
            <p>Are you sure you want to deactivate this pricing plan? This will not affect existing subscriptions.</p>
            <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px", marginTop: "16px" }}>
              <Button 
                variant="outlined"
                color="secondary"
                onClick={() => setShowDeleteDialog(false)}
                label="Cancel"
              />
              <Button 
                variant="contained"
                color="error"
                onClick={handleDelete}
                disabled={loading}
                label={loading ? "Deactivating..." : "Deactivate"}
              />
            </div>
          </div>
        </div>
      )}

      {/* Toast notification displayed using a simple div */}
      {toastMessage && (
        <div style={{
          position: "fixed",
          bottom: 16,
          right: 16,
          padding: "10px 20px",
          background: toastType === "success" ? "#4caf50" : "#f44336",
          color: "white",
          borderRadius: 4,
          boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
          zIndex: 1000
        }}>
          {toastMessage}
        </div>
      )}
    </Layout>
  );
};

export default CompanyPricing; 