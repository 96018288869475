import React, { useMemo } from "react";

import { Button } from "@fleet.co/tarmac";
import styles from "./AddDeviceProductRow.module.scss";

import { productSpecs } from "../../../../data/productSpecs";
import { formatMoney } from "../../../../helpers/formatHelpers";

const AddDeviceProductRow = (props) => {
  const isPrinterCategory = props.product_group.category === "PRINTER";

  const formattedProduct = useMemo(() => {
    if (isPrinterCategory) {
      const { printer_characteristics, ...rest } = props.product;
      return {
        ...rest,
        paper_format: printer_characteristics?.paper_format,
        pages_per_month: printer_characteristics?.pages_per_month,
        has_maintenance_price: printer_characteristics?.has_maintenance_price,
        scanner_type: printer_characteristics?.scanner_type,
      };
    }
    return props.product;
  }, [props.product, isPrinterCategory]);

  const getShippingRange = () => {
    if (formattedProduct.min_shipping && formattedProduct.max_shipping) {
      return (
        <>
          {formattedProduct.min_shipping} À {formattedProduct.max_shipping} JOURS{" "}
        </>
      );
    }

    return "-";
  };

  const price = useMemo(() => {
    if (!formattedProduct) return null;

    const currentCurrency = props.order.rent_currency;

    return formatMoney(
      formattedProduct?.prices.find((price) => price.currency === currentCurrency)?.amount,
      currentCurrency,
    );
  }, [formattedProduct, props.order]);

  return (
    <div className={styles.productRow}>
      <div className={styles.price}>{price}</div>
      {productSpecs
        .filter((spec) => spec.categories.includes(props.product_group.category) && spec.applyTo.includes("PRODUCT"))
        .map((spec) => (
          <div>
            {spec.options(props.product_group.category).find((o) => o.value === formattedProduct[spec.name])?.label}
          </div>
        ))}
      <div className={styles.shipping}>{getShippingRange()}</div>
      <Button
        variant="contained"
        color="primary"
        label="Add"
        onClick={() => props.openModal(props.product_group, formattedProduct)}
      />
    </div>
  );
};

export default AddDeviceProductRow;
