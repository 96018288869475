import type { Company } from "src/types/Company.type";
import { getMdmARR, getAppsARR } from "./getARR";

/**
 * Safely calculates ARR values for MDM and Apps with error handling
 * @param company The company to calculate ARR values for
 * @returns Object containing mdmARR, mdmPrediction, appsARR, and appsPrediction values
 */
export const calculateARRValues = (company: Company) => {
  // Default values
  const defaultValues = { current: 0, prediction: 0 };
  
  // Calculate MDM ARR with error handling
  let mdmValues = defaultValues;
  try {
    mdmValues = getMdmARR(company);
  } catch (error) {
    // Silently fail and use default values
  }
  
  // Calculate Apps ARR with error handling
  let appsValues = defaultValues;
  try {
    appsValues = getAppsARR(company);
  } catch (error) {
    // Silently fail and use default values
  }
  
  return {
    mdmARR: mdmValues.current,
    mdmPrediction: mdmValues.prediction,
    appsARR: appsValues.current,
    appsPrediction: appsValues.prediction
  };
}; 