/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  faCircleCheck,
  faCircleQuestion,
  faCircleXmark,
  faClock,
  faHome,
  faMailbox,
  faQuestionCircle,
  faSuitcase,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import {
  Accordion,
  Alert,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Dialog,
  Drawer,
  FileContainer,
  FileDropzone,
  Heading,
  Icon,
  Layout,
  Link,
  Select,
  Separator,
  Text,
  TextField,
  Tooltip,
} from "@fleet.co/tarmac";
import { FormControl } from "@mui/material";
import { CURRENCIES_LIST } from "src/common/i18n-consts";
import UserContext from "../../tools/UserContext";
import { useToastContext } from "../../contexts/ToastContext";
import styles from "./EditDeviceModal.module.scss";

import { calculateGrossMarginAmount, calculateGrossMarginPercentage } from "../../tools/OrderTools";
import { deviceStatus } from "../../data/deviceStatus";
import { productSpecs } from "../../data/productSpecs";
import { carriers } from "../../data/carriers";
import FormatDate from "../../tools/FormatDate";
import { downloadFileBlob } from "../../helpers/FilesHelpers";
import MismatchingSkuAltert from "./MismatchingSkuAlert";

const deviceEmptyState = {
  // Status
  status: "WAITING",
  end_of_life_status: "WAITING",
  renewal_type: "",
  usage_status: "UNASSIGNED",
  // Device
  quality: "",
  name: "",
  screen_size: "",
  storage: "",
  processor: "",
  keyboard_layout: "",
  ram: "",
  graphic: "",
  connectivity: "",
  has_microphone: "",
  connection_type: "",
  compliance: "",
  noise_cancellation: "",
  os_platform: "",

  // Sourcing
  provider: "",
  provider_country_id: "",
  provider_request_number: "",
  provider_invoice_number: "",
  sku: {
    sku_code: "",
  },
  acquisition_price: "",
  acquisition_currency: "",
  model_year: "",
  shipping_fee: "",
  guarantee: "",
  warranty_cost: 0,
  serial_number: "",

  // !Delivery
  address: "",
  contact: "",
  tracking_number: "",
  tracking_url: "",
  delivery_code: "",
  aftership_tag: "",
  carrier: "",
  promised_delivery_date: null,
  expected_delivery_date: null,

  // !Dates
  purchase_date: "",
  delivery_date: "",
  shipping_date: "",
  update_date: "",
  creation_date: "",
  reception_date: "",
  signature_date: "",

  // !Billing and Discount
  rent: 0,
  base_price: 0,
  discount: 0,
  upgrade: 0,
  company_discount: 0,
  month_offered: false,

  // !Return
  residual_value: "",
  resell_value: "",
  buyback_value: "",
  activeReturn: null,
  penaltyAmountDue: 0,
  pj_erase_certificate: "",

  // Take every spec from productSpecs and create a key with the spec name and an empty value
  ...productSpecs
    .filter((spec) => spec.applyTo.includes("DEVICE"))
    .reduce((acc, spec) => ({ ...acc, [spec.name]: spec.multiple ? [] : "" }), {}),

  device_mdm: {},
};

/**
 * Parses the printer maintenance prices amounts to ensure they are floats with 3 decimal places
 * @param {Array} printerMaintenancePrices - The printer maintenance prices to parse
 * @returns {Array} The parsed printer maintenance prices
 */
const parsePrinterMaintenancePricesAmounts = (printerMaintenancePrices) => {
  return printerMaintenancePrices.map((price) => ({
    ...price,
    amount: parseFloat(parseFloat(price.amount).toFixed(3)),
  }));
};

/**
 * Builds the update device payload that will be sent to the server
 * @param {Object} device - The device to update
 * @returns {Object} The update device payload
 */
const buildUpdateDevicePayload = (device) => {
  const isDevicePrinter = device.category === "PRINTER";
  if (!isDevicePrinter) return device;

  return {
    ...device,
    product: {
      ...device.product,
      printer_maintenance_prices: device.product.printer_maintenance_prices
        ? parsePrinterMaintenancePricesAmounts(device.product.printer_maintenance_prices)
        : [],
    },
  };
};

const carriersOptions = [{ label: "-", value: "" }, ...carriers];

const EditDeviceModal = (props) => {
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [expandReturn, setExpandReturn] = useState(false);
  const [expandBasicInfo, setExpandBasicInfo] = useState(false);
  const [expandRenewal, setExpandRenewal] = useState(false);
  const [expandDevice, setExpandDevice] = useState(false);
  const [expandSourcing, setExpandSourcing] = useState(false);
  const [expandDelivery, setExpandDelivery] = useState(false);
  const [expandDates, setExpandDates] = useState(false);
  const [expandBilling, setExpandBilling] = useState(false);
  const [expandManagement, setExpandManagement] = useState(false);
  const [providersOptions, setProvidersOptions] = useState(null);
  const [addressArray, setAddressArray] = useState([]);
  const [order, setOrder] = useState(null);
  const { user: adminUser } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [resetMdmLoading, setResetMdmLoading] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState(deviceEmptyState);
  const [eraseCertificate, setEraseCertificate] = useState(null);
  const { addToast } = useToastContext();
  const isReturnRenewal = ["RENEW", "STOP", "BROKEN"].includes(deviceInfo.renewal_type);

  const usage_status_options = [
    { label: "Not Defined", value: "" },
    { label: "Unassigned", value: "UNASSIGNED" },
    { label: "Running", value: "RUNNING" },
    { label: "Spare", value: "SPARE" },
    { label: "SAV", value: "SAV" },
    { label: "Out of service", value: "OUT_OF_SERVICE" },
  ];

  const returnStatusOptions = [
    { label: "Waiting", value: "WAITING" },
    { label: "Scheduled", value: "SCHEDULED" },
    { label: "Shipped", value: "SHIPPED" },
    { label: "Received", value: "RECEIVED" },
    { label: "Failed", value: "FAILED" },
  ];

  const renewal_type_options = [
    { label: "Not Done", value: "" },
    { label: "Renew", value: "RENEW" },
    { label: "Stop", value: "STOP" },
    { label: "Buyback", value: "BUYBACK" },
    { label: "Lost", value: "LOST" },
    { label: "Broken", value: "BROKEN" },
    { label: "Upgrade", value: "UPGRADE" },
  ];

  const eol_status_options = [
    { label: isReturnRenewal ? "Waiting for Return" : "Waiting for Payment", value: "WAITING" },
    { label: isReturnRenewal ? "Return Done" : "Payment Done", value: "DONE" },
  ];

  const warrantyDuration = [
    { label: "12", value: 12 },
    { label: "24", value: 24 },
    { label: "36", value: 36 },
  ];

  const currencies = [
    { label: "-", value: "" },
    ...CURRENCIES_LIST.map((currency) => ({
      label: currency,
      value: currency,
    })),
  ];

  const typologyKey = {
    new: "Neuf",
    asnew: "Certifié Apple",
    refurb: "Refurbished Premium",
  };

  const buybackPayment = props.device?.payments?.find((p) => p.type === "BUYBACK");

  const getBuybackPaymentIcon = useCallback(() => {
    switch (buybackPayment.status) {
      case "PAID":
        return faCircleCheck;
      case "OPEN":
        return faClock;
      case "DELETED":
        return faCircleXmark;
      default:
        return faCircleQuestion;
    }
  }, [buybackPayment]);

  const confirmDelete = async () => {
    try {
      await adminUser.api.deleteDevice(props.device?.id);
      setConfirmDeleteModal(false);
      props.onClose();
      props.reloadPage();
    } catch (err) {
      addToast(err.response.data.message, "error");
    }
  };

  const saveDevice = async () => {
    // Guard if modal has been loaded with blank data
    if (!deviceInfo.id) return;
    // Clean device info for server
    deviceInfo.shipping_address_id = deviceInfo.address.id;
    // Replace commas with dots to convert to Number format
    deviceInfo.acquisition_price = deviceInfo.acquisition_price.replaceAll(",", ".");
    deviceInfo.tracking_number = deviceInfo.tracking_number?.trim() === "" ? null : deviceInfo.tracking_number;
    deviceInfo.tracking_url = deviceInfo.tracking_url?.trim() === "" ? null : deviceInfo.tracking_url;
    try {
      setIsloading(true);
      // Remove property order from device info before sending it
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { _order, ...deviceInfoToSend } = deviceInfo;
      const updateDevicePayload = buildUpdateDevicePayload(deviceInfoToSend);
      await adminUser.api.modifyDevice(props.device?.id, updateDevicePayload);
      await props.onClose();
      await props.reloadPage();
    } catch (err) {
      if (err.response?.data?.message) {
        setError(err.response.data.message);
      } else {
        setError("An error has occured while saving the device");
      }
    } finally {
      setIsloading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "delivery_cost" && deviceInfo.activeReturn) {
      setDeviceInfo({ ...deviceInfo, activeReturn: { ...deviceInfo.activeReturn, cost: value } });
    } else if (name === "discount" || name === "upgrade") {
      setDeviceInfo({ ...deviceInfo, [name]: value.replace(",", ".") });
    } else if (name === "carrier") {
      setDeviceInfo({ ...deviceInfo, [name]: value.toLowerCase().replace(" ", "-") });
    } else if (name === "sku") {
      setDeviceInfo({ ...deviceInfo, sku: { ...deviceInfo.sku, sku_code: value } });
    } else {
      setDeviceInfo({ ...deviceInfo, [name]: value });
    }
  };

  const handleSelectChange = (name, value) => {
    if (name === "month_offered" && !value) {
      setDeviceInfo({ ...deviceInfo, discount: 0, month_offered: false });
    } else if (name === "return_status" && deviceInfo.activeReturn) {
      setDeviceInfo({ ...deviceInfo, activeReturn: { ...deviceInfo.activeReturn, status: value } });
    } else if (name === "provider") {
      const providerData = {
        warranty_cost: 5,
        [name]: value,
      };

      setDeviceInfo({ ...deviceInfo, ...providerData });
    } else if (printerCharacteristicsKeys.includes(name)) {
      setDeviceInfo({
        ...deviceInfo,
        product: {
          ...deviceInfo.product,
          printer_characteristics: { ...deviceInfo.product.printer_characteristics, [name]: value },
        },
      });
    } else {
      setDeviceInfo({ ...deviceInfo, [name]: value });
    }
  };

  const confirmModalDeleteDevice = () => {
    setConfirmDeleteModal(true);
  };

  const deviceGBS = useMemo(() => {
    const contractDuration = deviceInfo.order?.contract_duration;

    if (!contractDuration) return 0;

    return `${deviceInfo.rent * contractDuration}€`;
  }, [deviceInfo.rent, deviceInfo.order?.contract_duration]);

  const displayContributionMargin = () => {
    let textColor = styles.fleetRed;
    let info = "";

    if (!props.device?.rent) {
      info = "rent is missing";
    } else if (!deviceInfo.acquisition_price) {
      info = "acquisition_price is mising";
    } else {
      const contributionMargin = calculateGrossMarginPercentage(deviceInfo, order);

      if (contributionMargin) {
        info = `${contributionMargin} `;
        if (contributionMargin < 35) {
          info += " Warning";
        } else if (contributionMargin < 40) {
          textColor = styles.fleetGreen;
          info += "Good";
        } else {
          textColor = styles.fleetGreen;
          info += "Perfect !";
        }
      }
    }

    return <span className={textColor}>{info}</span>;
  };

  const calculateDiscount = (basePrice, discount, upgrade) => {
    if (Number.isNaN(parseFloat(discount))) {
      discount = 0;
    }

    if (Number.isNaN(parseFloat(upgrade))) {
      upgrade = 0;
    }
    const res = parseFloat(upgrade) + parseFloat(basePrice) - parseFloat(discount);

    if (Number.isNaN(res)) return basePrice;

    return res.toFixed(2);
  };

  useEffect(() => {
    const createAddressArray = () => {
      const res = [];

      deviceInfo.order?.company.addresses.forEach((e) => {
        if ((!e.address1 || !e.address2) && !e.zip && !e.city) return;
        let addressLabel = e.address1;

        if (e.firstName) addressLabel += ` ${e.firstName}`;

        if (e.lastName) addressLabel += ` ${e.lastName}`;

        if (e.label) addressLabel += ` ${e.label}`;

        if (e.city) addressLabel += ` ${e.city}`;

        if (e.country) addressLabel += ` ${e.country}`;

        res.push({
          label: addressLabel,
          value: e.id,
        });
      });
      setAddressArray(res);
    };

    createAddressArray();
  }, [deviceInfo.carrier, deviceInfo.tracking_number, deviceInfo.order?.company.addresses]);

  const handleAdressSelect = (value) => {
    const res = props.device?.order.company.addresses.find((e) => e.id === value);

    if (res) setDeviceInfo({ ...deviceInfo, address: res });
  };

  const dateConverterToddmmyyyy = (date) => {
    const pad = (s) => (s < 10 ? `0${s}` : s);

    return [pad(date.getDate()), pad(date.getMonth() + 1), date.getFullYear()].join("/");
  };

  const datesToDisplay = [
    {
      name: "purchase_date",
      label: "Purchase",
    },
    {
      name: "shipping_date",
      label: "Shipping",
    },
    {
      name: "delivery_date",
      label: "Delivery",
    },
    {
      name: "reception_date",
      label: "Reception",
    },
  ];

  const submitFile = async (file) => {
    const formData = new FormData();

    formData.append("type", "PJ_ERASE_CERTIFICATE");
    formData.append("status", "ACCEPTED");
    formData.append("device_id", props.device?.id);
    formData.append("company_id", props.device?.company_id);
    formData.append("file_contents", file);
    await adminUser.api.addFile(formData);
    setEraseCertificate(file);
  };

  const deleteFile = async (file) => {
    if (file.id) await adminUser.api.deleteFile(file.id);
    setEraseCertificate(null);
  };

  const downloadFile = async (file) => {
    if (file.id) {
      const response = await adminUser.api.getFile(file.id);

      downloadFileBlob(response.data, file.original_name || file.url);
    }
  };

  const resetMdm = useCallback(async () => {
    try {
      setResetMdmLoading(true);
      await adminUser.api.resetDeviceMdmEnrollment(deviceInfo.id);
      setResetMdmLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_err) {
      setResetMdmLoading(false);
    }
  }, [adminUser.api, deviceInfo.id]);

  const handleClose = () => {
    setDeviceInfo(deviceEmptyState);
    props.onClose();
  };

  const fetchOrder = useCallback(async () => {
    if (!props.device?.order_id) return;

    const fetchedOrder = await adminUser.api.getOrder(props.device?.order_id);

    setOrder(fetchedOrder.data);
  }, [props.device?.order_id, adminUser.api]);

  const updateDeviceInfos = useCallback(() => {
    if (!props.device) return;
    // Remove every null values from object so it's overidden by deviceEmptyState https://stackoverflow.com/a/38340730
    const deviceFromPropsWithoutNull = Object.fromEntries(Object.entries(props.device).filter(([, v]) => v != null));

    const initializedDevice = {
      ...deviceEmptyState,
      ...deviceFromPropsWithoutNull,
    };

    setDeviceInfo(initializedDevice);
  }, [props.device]);

  useEffect(() => {
    updateDeviceInfos();
    fetchOrder();
    const eraseCertificate = props.device?.files?.find((file) => file.type === "PJ_ERASE_CERTIFICATE") || null;

    setEraseCertificate(eraseCertificate);
  }, [updateDeviceInfos, fetchOrder, props.device?.id]);

  useEffect(() => {
    const fetchProviders = async () => {
      const { data: providers } = await adminUser.api.getProviders();

      const providersOptions = providers.map((provider) => ({
        label: provider.name,
        value: provider.name,
        countries: provider.countries,
      }));

      setProvidersOptions(providersOptions);
    };

    fetchProviders();
  }, [adminUser.api, deviceInfo.provider]);

  const ActionComponent = (
    <>
      <Button color="secondary" label="No" onClick={() => setConfirmDeleteModal(false)} />
      <Button color="error" variant="contained" loading={isLoading} label="Yes" onClick={confirmDelete} />
    </>
  );

  const Actions = (
    <>
      <Button variant="contained" color="primary" onClick={saveDevice} loading={isLoading} label="Save" />

      <Button variant="contained" color="error" onClick={confirmModalDeleteDevice} label="Delete device" />
    </>
  );

  const keyboardSpec = productSpecs.find((spec) => spec.name === "keyboard_layout");
  const keyboardValues = keyboardSpec.options().map((option) => option.value);
  const printerCharacteristicsKeys = ["pages_per_month", "paper_format", "scanner_type"];
  const savedSkuProductId = props?.device?.sku?.product_id;

  const addressTypes = {
    OFFICE: { icon: faSuitcase, label: "Office" },
    HOME: { icon: faHome, label: "Home" },
    PO_BOX: { icon: faMailbox, label: "PO Box" },
  };

  const selectedProvider = providersOptions?.find((provider) => provider.value === deviceInfo.provider);
  const providerCountriesOption = selectedProvider?.countries.map((country) => ({
    label: country.name,
    value: country.id,
  }));

  const filteredAddressArray = addressArray.filter(
    (address) =>
      address.label !== null &&
      (address.address1 !== null || address.address2 !== null) &&
      address.zip !== null &&
      address.city !== null &&
      address.country !== null,
  );

  return (
    <>
      <Drawer title="Edit device" onClose={handleClose} open={props.open} Actions={Actions}>
        <Layout direction="column" isScrollable>
          {deviceInfo?.activeReturn && (
            <Accordion
              title="Return"
              defaultExpanded
              expanded={expandReturn}
              onChange={() => setExpandReturn(!expandReturn)}
            >
              <Layout direction="column" gap={8}>
                <Select
                  label="Return status"
                  value={deviceInfo.activeReturn.status || ""}
                  options={returnStatusOptions}
                  onChange={(value) => handleSelectChange("return_status", value)}
                />

                <Layout direction="column">
                  <Text variant="body2" bold>
                    Recipient:
                  </Text>
                  <Text variant="body2">
                    {props.device?.activeReturn.recipient ? props.device?.activeReturn.recipient : "-"}
                  </Text>
                </Layout>
                <Layout direction="column">
                  <Text variant="body2" bold>
                    Pickup Date:
                  </Text>
                  <Text variant="body2">
                    <FormatDate date={props.device?.activeReturn.pickup_date} />
                  </Text>
                </Layout>
                <Layout direction="column">
                  <Text variant="body2" bold>
                    Shipping Date:
                  </Text>
                  <Text variant="body2">
                    <FormatDate date={props.device?.activeReturn.shipping_date} />
                  </Text>
                </Layout>
                <Layout direction="column">
                  <Text variant="body2" bold>
                    Reception Date:
                  </Text>
                  <Text variant="body2">
                    <FormatDate date={props.device?.activeReturn.reception_date} />
                  </Text>
                </Layout>
                {props.device?.activeReturn.label_url && (
                  <Link
                    label="Link to the label"
                    iconSide="right"
                    onClick={() => window.open(props.device?.activeReturn.label_url, "_blank")}
                  />
                )}
              </Layout>
            </Accordion>
          )}
          <Accordion
            title="Basic Info"
            defaultExpanded
            expanded={expandBasicInfo}
            onChange={() => setExpandBasicInfo(!expandBasicInfo)}
          >
            <Layout direction="column" spacing={2}>
              <Select
                className={styles.inputField}
                label="Device status"
                value={deviceInfo.status || ""}
                options={deviceStatus}
                onChange={(value) => handleSelectChange("status", value)}
              />

              <Select
                className={styles.inputField}
                label="Usage status"
                value={deviceInfo.usage_status || ""}
                onChange={(value) => handleSelectChange("usage_status", value)}
                options={usage_status_options}
              />

              <Select
                className={styles.inputField}
                label="Renewal Type"
                value={deviceInfo.renewal_type || ""}
                options={renewal_type_options}
                onChange={(value) => handleSelectChange("renewal_type", value)}
              />

              <Select
                className={styles.inputField}
                disabled={deviceInfo.renewal_type === ""}
                label="End of Life Status"
                value={deviceInfo.end_of_life_status || ""}
                options={eol_status_options}
                onChange={(value) => handleSelectChange("end_of_life_status", value)}
              />

              <Text variant="caption">
                This status should automatically move to <strong>DONE</strong> once we have received the money or the
                device.
                <br />
                Only change if you are sure device return/payment is complete.
              </Text>
            </Layout>
          </Accordion>
          {Boolean(deviceInfo.renewal_type) && (
            <Accordion title="Renewal" expanded={expandRenewal} onChange={() => setExpandRenewal(!expandRenewal)}>
              <Layout direction="column" spacing={2}>
                <Layout direction="row" spacing={2} alignItems="center">
                  <TextField
                    name="resell_value"
                    label="Resell value"
                    type="number"
                    onChange={handleInputChange}
                    value={deviceInfo.resell_value}
                    onKeyDown={(e) => ["e", "E", ","].includes(e.key) && e.preventDefault()}
                  />
                  <Tooltip title="Resale value to reconditioners" placement="left">
                    <Icon icon={faCircleQuestion} color="secondary" size="S" />
                  </Tooltip>
                  {deviceInfo.activeReturn && (
                    <TextField
                      label="Delivery Cost ( Upela Label )"
                      name="delivery_cost"
                      type="number"
                      onChange={handleInputChange}
                      value={deviceInfo.activeReturn.cost}
                      onKeyDown={(e) => ["e", "E", ","].includes(e.key) && e.preventDefault()}
                    />
                  )}
                </Layout>
                <Layout direction="row" spacing={2}>
                  <TextField
                    name="penalty_amount_due"
                    label="Penalty amount due"
                    type="number"
                    onChange={handleInputChange}
                    value={deviceInfo.penaltyAmountDue}
                    onKeyDown={(e) => ["e", "E", ","].includes(e.key) && e.preventDefault()}
                    disabled
                  />
                  <TextField
                    name="residual_value"
                    label="VR value"
                    type="number"
                    onChange={handleInputChange}
                    value={deviceInfo.residual_value}
                    onKeyDown={(e) => ["e", "E", ","].includes(e.key) && e.preventDefault()}
                  />
                </Layout>
                <Layout direction="row" spacing={2} alignItems="center">
                  <Layout direction="row" spacing={2} alignItems="center">
                    <TextField
                      label="Buyback Value"
                      name="buyback_value"
                      type="number"
                      onChange={handleInputChange}
                      value={deviceInfo.buyback_value}
                      onKeyDown={(e) => ["e", "E", ","].includes(e.key) && e.preventDefault()}
                    />
                    <Tooltip title="Resale value to the customer" placement="left">
                      <Icon icon={faCircleQuestion} color="secondary" size="S" />
                    </Tooltip>
                    {buybackPayment && (
                      <Tooltip
                        placement="left"
                        title={
                          <Layout direction="column">
                            <Text variant="body1">status :{buybackPayment.status}</Text>
                            <Text variant="body1">paid at :{buybackPayment.paid_at || "-"}</Text>
                            <Text variant="body1">created at :{buybackPayment.created_at || "-"}</Text>
                          </Layout>
                        }
                      >
                        <Icon icon={getBuybackPaymentIcon()} color="secondary" size="S" />
                      </Tooltip>
                    )}
                  </Layout>
                </Layout>
                <Layout direction="row" spacing={2}>
                  <Layout direction="column" spacing={2} fullWidth>
                    <Text variant="body2">PJ Erase Certificate</Text>
                    {eraseCertificate ? (
                      <FileContainer
                        files={eraseCertificate}
                        onCardClick={downloadFile}
                        onIconClick={deleteFile}
                        icon={faTrashCan}
                      />
                    ) : (
                      <FileDropzone
                        onFilesAdded={(files) => submitFile(files[0])}
                        helperText="Drag and drop or select"
                        errorText="An error has occured"
                      />
                    )}
                  </Layout>
                </Layout>
              </Layout>
            </Accordion>
          )}
          <Accordion title="Device" expanded={expandDevice} onChange={() => setExpandDevice(!expandDevice)}>
            <Layout direction="column" spacing={2}>
              {deviceInfo && (
                <Card backgroundColor="secondary">
                  <Text variant="body1">
                    {deviceInfo.name} <Text variant="caption" color="secondary">{`(id: ${deviceInfo.id})`}</Text>
                  </Text>
                  {props.device?.type === "DEVICE" && (
                    <>
                      {deviceInfo.processor}
                      <br />
                      {deviceInfo.graphic}
                    </>
                  )}
                </Card>
              )}
              <Text variant="body2">{typologyKey[deviceInfo.quality]}</Text>
              <Layout direction="column" fullWidth spacing={2}>
                {productSpecs
                  .filter(
                    (spec) =>
                      spec.name !== "keyboard_layout" &&
                      spec.categories.includes(props.device?.category) &&
                      spec.applyTo.includes("DEVICE"),
                  )
                  .map((spec) => {
                    return (
                      <FormControl fullWidth key={spec.name}>
                        <Select
                          fullWidth
                          key={spec.name}
                          label={spec.label}
                          value={
                            deviceInfo.category === "PRINTER" && printerCharacteristicsKeys.includes(spec.name)
                              ? deviceInfo.product?.printer_characteristics?.[spec.name]
                              : deviceInfo[spec.name]
                          }
                          options={spec.options(props.device?.category)}
                          onChange={(value) => handleSelectChange(spec.name, value)}
                        />
                      </FormControl>
                    );
                  })}
                {productSpecs
                  .filter(
                    (spec) =>
                      spec.name === "keyboard_layout" &&
                      spec.categories.includes(props.device?.category) &&
                      spec.applyTo.includes("DEVICE"),
                  )
                  .map((spec) => (
                    <FormControl fullWidth key={spec.name}>
                      <Select
                        fullWidth
                        key={spec.name}
                        label={spec.label}
                        value={!keyboardValues.includes(deviceInfo[spec.name]) ? "other" : deviceInfo[spec.name]}
                        options={spec.options(props.device?.category)}
                        onChange={(value) => handleSelectChange(spec.name, value)}
                      />
                    </FormControl>
                  ))}
              </Layout>
            </Layout>
          </Accordion>
          <Accordion title="Sourcing" expanded={expandSourcing} onChange={() => setExpandSourcing(!expandSourcing)}>
            <Layout direction="column" spacing={2}>
              <TextField
                label="Model year"
                name="model_year"
                onChange={handleInputChange}
                value={deviceInfo.model_year}
              />
              <Select
                key={deviceInfo.provider}
                label="Supplier"
                value={deviceInfo.provider}
                options={providersOptions}
                onChange={(value) => handleSelectChange("provider", value)}
              />
              {Boolean(providerCountriesOption?.length) && (
                <Select
                  key={deviceInfo.provider_country_id}
                  label="Supplier country"
                  value={deviceInfo.provider_country_id}
                  options={providerCountriesOption}
                  onChange={(value) => handleSelectChange("provider_country_id", value)}
                />
              )}
              <TextField
                label="Supplier PO number"
                name="provider_request_number"
                onChange={handleInputChange}
                value={deviceInfo.provider_request_number}
              />
              {deviceInfo.provider === "Ingram" && deviceInfo.provider_request_number !== "" && (
                <>
                  <TextField label="SKU" name="sku" onChange={handleInputChange} value={deviceInfo.sku?.sku_code} />
                  {savedSkuProductId && savedSkuProductId !== deviceInfo?.product_id && (
                    <MismatchingSkuAltert productId={savedSkuProductId} />
                  )}
                </>
              )}

              <TextField
                label="Supplier invoice number"
                name="provider_invoice_number"
                onChange={handleInputChange}
                value={deviceInfo.provider_invoice_number}
              />
              <TextField label="Device GBS" value={deviceGBS} disabled />
              <TextField
                label="Device cost"
                name="acquisition_price"
                onChange={handleInputChange}
                value={deviceInfo.acquisition_price}
              />
              <Select
                label="Acquisition currency"
                value={deviceInfo.acquisition_currency}
                options={currencies}
                onChange={(value) => handleSelectChange("acquisition_currency", value)}
              />
              {order && (
                <Layout direction="row" spacing={2}>
                  <Layout direction="column" spacing={2}>
                    <Text variant="body2">Gross margin (%)</Text>
                    {displayContributionMargin()}
                  </Layout>
                  <Layout direction="column" spacing={2}>
                    <Text variant="body2">{`Gross margin (${order.rent_currency === "EUR" ? "€" : "£"})`}</Text>
                    {calculateGrossMarginAmount(props.device, order)?.toFixed(2) || "-"}
                  </Layout>
                </Layout>
              )}
              <TextField
                label="Serial number"
                name="serial_number"
                onChange={handleInputChange}
                value={deviceInfo.serial_number}
              />
              <TextField
                label="Shipping cost"
                name="shipping_fee"
                onChange={handleInputChange}
                value={deviceInfo.shipping_fee}
              />
              <Select
                label="Warranty period"
                value={deviceInfo.guarantee}
                options={warrantyDuration}
                onChange={(value) => handleSelectChange("guarantee", value)}
              />
              <TextField
                label="Warranty cost"
                name="warranty_cost"
                value={deviceInfo.warranty_cost}
                onChange={handleInputChange}
              />
            </Layout>
          </Accordion>
          <Accordion title="Delivery" expanded={expandDelivery} onChange={() => setExpandDelivery(!expandDelivery)}>
            <Layout direction="column" spacing={2}>
              {deviceInfo.address && deviceInfo.address.id && (
                <Card backgroundColor="secondary">
                  <Icon icon={addressTypes[deviceInfo.address.type || "OFFICE"].icon} /> {deviceInfo.address.firstName}{" "}
                  {deviceInfo.address.lastName}
                  <br />
                  {deviceInfo.address.address1}
                  <br />
                  {deviceInfo.address.address2}
                  <br />
                  {deviceInfo.address.zip} {deviceInfo.address.city}
                  <br />
                  {deviceInfo.address.phone}
                </Card>
              )}
              <Layout direction="column" spacing={2}>
                {addressArray.length && (
                  <Select
                    fullWidth
                    label="Address"
                    options={filteredAddressArray}
                    onChange={(value) => handleAdressSelect(value)}
                    value={deviceInfo.address.id}
                  />
                )}
                <TextField
                  label="Contact"
                  name="address"
                  disabled
                  value={`${deviceInfo.address.firstName || ""} ${
                    deviceInfo.address.lastName || ""
                  } ${deviceInfo.address.phone || ""}`}
                />
                <TextField
                  label="Tracking number"
                  name="tracking_number"
                  onChange={handleInputChange}
                  value={deviceInfo.tracking_number}
                />
                <TextField
                  label="Tracking url"
                  name="tracking_url"
                  onChange={handleInputChange}
                  value={deviceInfo.tracking_url}
                />
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  label="Follow the delivery"
                  disabled={!props.device?.tracking_number}
                  href={`https://fleet.aftership.com/${props.device?.tracking_number}?page-name=tracking-page`}
                  targetBlank
                />
                {deviceInfo.tracking_number && (
                  <Layout direction="column" spacing={2}>
                    <Select
                      label="Carrier"
                      value={deviceInfo.carrier}
                      options={carriersOptions}
                      onChange={(value) => handleSelectChange("carrier", value)}
                    />
                    <Layout direction="row" spacing={2}>
                      <TextField
                        label="Carrier slug"
                        name="carrier"
                        onChange={handleInputChange}
                        value={deviceInfo.carrier}
                      />
                      <Icon
                        icon={faQuestionCircle}
                        color="secondary"
                        size="S"
                        onClick={() =>
                          window.open("https://www.aftership.com/docs/tracking/others/supported-couriers", "_blank")
                        }
                      />
                    </Layout>
                  </Layout>
                )}
                <DatePicker
                  label="Promised Delivery Date"
                  name="promised_delivery_date"
                  onChange={(date) => setDeviceInfo({ ...deviceInfo, promised_delivery_date: date })}
                  value={deviceInfo.promised_delivery_date}
                />
                <DatePicker
                  label="Estimated Delivery Date"
                  name="expected_delivery_date"
                  onChange={(date) => setDeviceInfo({ ...deviceInfo, expected_delivery_date: date })}
                  value={deviceInfo.expected_delivery_date}
                />
                <DatePicker
                  label="Actual Delivery Date"
                  name="delivery_date"
                  onChange={(date) => setDeviceInfo({ ...deviceInfo, delivery_date: date })}
                  value={deviceInfo.delivery_date}
                />
                <DatePicker
                  label="Purchase date"
                  name="purchase_date"
                  onChange={(date) => setDeviceInfo({ ...deviceInfo, purchase_date: date })}
                  value={deviceInfo.purchase_date}
                />
                <DatePicker
                  label="Shipping date"
                  name="shipping_date"
                  onChange={(date) => setDeviceInfo({ ...deviceInfo, shipping_date: date })}
                  value={deviceInfo.shipping_date}
                />
                <DatePicker
                  label="Reception date"
                  name="reception_date"
                  onChange={(date) => setDeviceInfo({ ...deviceInfo, reception_date: date })}
                  value={deviceInfo.reception_date}
                />
                <TextField
                  label="Delivery Code"
                  name="delivery_code"
                  onChange={handleInputChange}
                  value={deviceInfo.delivery_code}
                />
              </Layout>
            </Layout>
          </Accordion>
          {datesToDisplay.some((date) => deviceInfo[date.name]) && (
            <Accordion title="Dates" expanded={expandDates} onChange={() => setExpandDates(!expandDates)}>
              <Layout direction="row" spacing={2}>
                {datesToDisplay.map((date) => {
                  if (deviceInfo[date.name]) {
                    return (
                      <Layout direction="column" spacing={2} key={date.name}>
                        <Text variant="body1">{date.label}</Text>
                        <Text variant="body2">{dateConverterToddmmyyyy(new Date(deviceInfo[date.name]))}</Text>
                      </Layout>
                    );
                  }

                  return null;
                })}
              </Layout>
            </Accordion>
          )}
          <Accordion
            title="Invoicing and Discount"
            expanded={expandBilling}
            onChange={() => setExpandBilling(!expandBilling)}
          >
            <Layout direction="row" spacing={2}>
              <Layout direction="column" spacing={2}>
                <Heading variant="subtitle1">Invoicing</Heading>
                <Layout direction="column" spacing={2}>
                  <Text variant="body2">Base price</Text>
                  <Text variant="body1">{deviceInfo.base_price}</Text>
                </Layout>
                <Separator orientation="horizontal" />
                <Layout direction="column" spacing={2}>
                  <TextField
                    label="Company Discount"
                    name="company_discount"
                    disabled
                    value={deviceInfo.company_discount}
                  />
                </Layout>
                <Separator orientation="horizontal" />
                <Layout direction="column" spacing={2}>
                  <TextField
                    label="Discount"
                    name="discount"
                    type="number"
                    onChange={handleInputChange}
                    value={deviceInfo.discount}
                  />
                </Layout>
                <Separator orientation="horizontal" />
                <Layout direction="column" spacing={2}>
                  <TextField
                    label="Upgrade"
                    name="upgrade"
                    type="number"
                    onChange={handleInputChange}
                    value={deviceInfo.upgrade}
                  />
                </Layout>
                <Card backgroundColor="primary">
                  <Layout direction="column" spacing={2}>
                    <Text variant="body1">Total price</Text>
                    <Text variant="body1">
                      {calculateDiscount(deviceInfo.base_price, deviceInfo.discount, deviceInfo.upgrade)}
                    </Text>
                  </Layout>
                </Card>
                <Text variant="body2">Discount</Text>
                <Checkbox
                  checked={deviceInfo.month_offered}
                  label="Month offered"
                  name="month_offered"
                  onChange={() => handleSelectChange({ name: "month_offered" }, { value: !deviceInfo.month_offered })}
                />
              </Layout>
            </Layout>
          </Accordion>

          {deviceInfo.device_mdm && (
            <Accordion title="MDM" expanded={expandManagement} onChange={() => setExpandManagement(!expandManagement)}>
              <Layout direction="column" spacing={2}>
                <TextField
                  type="number"
                  label="VMware Device ID"
                  value={deviceInfo.device_mdm?.vmware_device_id || ""}
                  disabled={props.device?.device_mdm?.vmware_device_id}
                  onChange={(e) =>
                    setDeviceInfo({
                      ...deviceInfo,
                      device_mdm: { ...deviceInfo.device_mdm, vmware_device_id: e.target.value },
                    })
                  }
                />

                <Button
                  variant="contained"
                  color="primary"
                  label="Reset MDM & Resend Enrollment Email"
                  onClick={resetMdm}
                  loading={resetMdmLoading}
                />
              </Layout>
            </Accordion>
          )}

          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}
        </Layout>
      </Drawer>

      <Dialog
        title="Delete a device"
        onClose={() => setConfirmDeleteModal(false)}
        Actions={ActionComponent}
        open={confirmDeleteModal}
      >
        This action will permanently delete this device. Would you like to continue ?
      </Dialog>
    </>
  );
};

export default EditDeviceModal;
